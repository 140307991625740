/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PermissionsDto } from '../models/permissions-dto';

@Injectable({
  providedIn: 'root',
})
export class PermissionsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPermissions
   */
  static readonly GetPermissionsPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsApiService.GetPermissionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissions(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updatePermission
   */
  static readonly UpdatePermissionPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePermission()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePermission$Response(params: {
    context?: HttpContext
    body: PermissionsDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsApiService.UpdatePermissionPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePermission$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePermission(params: {
    context?: HttpContext
    body: PermissionsDto
  }
): Observable<void> {

    return this.updatePermission$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAdminPermissions
   */
  static readonly GetAdminPermissionsPath = '/api/v1/adminportal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdminPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminPermissions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsApiService.GetAdminPermissionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdminPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminPermissions(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getAdminPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRolesBasedOnLoggedUserRole
   */
  static readonly GetRolesBasedOnLoggedUserRolePath = '/api/v2/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRolesBasedOnLoggedUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRolesBasedOnLoggedUserRole$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsApiService.GetRolesBasedOnLoggedUserRolePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRolesBasedOnLoggedUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRolesBasedOnLoggedUserRole(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.getRolesBasedOnLoggedUserRole$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPermissionsByAppId
   */
  static readonly GetPermissionsByAppIdPath = '/api/v2/{applicationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissionsByAppId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsByAppId$Response(params: {
    applicationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionsApiService.GetPermissionsByAppIdPath, 'get');
    if (params) {
      rb.path('applicationId', params.applicationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermissionsByAppId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsByAppId(params: {
    applicationId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.getPermissionsByAppId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
