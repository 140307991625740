/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createOrganization } from '../fn/organizations/create-organization';
import { CreateOrganization$Params } from '../fn/organizations/create-organization';
import { deleteOrganization } from '../fn/organizations/delete-organization';
import { DeleteOrganization$Params } from '../fn/organizations/delete-organization';
import { DeleteOrganizationResponse } from '../models/delete-organization-response';
import { GetAllOrganizationsResponse } from '../models/get-all-organizations-response';
import { getDomains } from '../fn/organizations/get-domains';
import { GetDomains$Params } from '../fn/organizations/get-domains';
import { getOrganizations } from '../fn/organizations/get-organizations';
import { GetOrganizations$Params } from '../fn/organizations/get-organizations';
import { getOrganizationsByIds } from '../fn/organizations/get-organizations-by-ids';
import { GetOrganizationsByIds$Params } from '../fn/organizations/get-organizations-by-ids';
import { getOrganizationsByUserId } from '../fn/organizations/get-organizations-by-user-id';
import { GetOrganizationsByUserId$Params } from '../fn/organizations/get-organizations-by-user-id';
import { getVersion } from '../fn/organizations/get-version';
import { GetVersion$Params } from '../fn/organizations/get-version';
import { onBoardOrganizationAndUser } from '../fn/organizations/on-board-organization-and-user';
import { OnBoardOrganizationAndUser$Params } from '../fn/organizations/on-board-organization-and-user';
import { OnBoardOrgUserResponse } from '../models/on-board-org-user-response';
import { setDefaultOrganization } from '../fn/organizations/set-default-organization';
import { SetDefaultOrganization$Params } from '../fn/organizations/set-default-organization';
import { softDeleteOrganization } from '../fn/organizations/soft-delete-organization';
import { SoftDeleteOrganization$Params } from '../fn/organizations/soft-delete-organization';
import { updateOrganization } from '../fn/organizations/update-organization';
import { UpdateOrganization$Params } from '../fn/organizations/update-organization';
import { UpdateOrganizationResponse } from '../models/update-organization-response';
import { validateUserInfo } from '../fn/organizations/validate-user-info';
import { ValidateUserInfo$Params } from '../fn/organizations/validate-user-info';

@Injectable({ providedIn: 'root' })
export class OrganizationsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setDefaultOrganization()` */
  static readonly SetDefaultOrganizationPath = '/api/v2/setDefaultOrg/{orgId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDefaultOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  setDefaultOrganization$Response(params: SetDefaultOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setDefaultOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setDefaultOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setDefaultOrganization(params: SetDefaultOrganization$Params, context?: HttpContext): Observable<void> {
    return this.setDefaultOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getOrganizations()` */
  static readonly GetOrganizationsPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations$Response(params?: GetOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllOrganizationsResponse>> {
    return getOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizations(params?: GetOrganizations$Params, context?: HttpContext): Observable<GetAllOrganizationsResponse> {
    return this.getOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllOrganizationsResponse>): GetAllOrganizationsResponse => r.body)
    );
  }

  /** Path part for operation `createOrganization()` */
  static readonly CreateOrganizationPath = '/api/v2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrganization$Response(params: CreateOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrganization(params: CreateOrganization$Params, context?: HttpContext): Observable<void> {
    return this.createOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getOrganizationsByIds()` */
  static readonly GetOrganizationsByIdsPath = '/api/v2/organizationIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationsByIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByIds$Response(params: GetOrganizationsByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getOrganizationsByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationsByIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByIds(params: GetOrganizationsByIds$Params, context?: HttpContext): Observable<void> {
    return this.getOrganizationsByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteOrganization()` */
  static readonly DeleteOrganizationPath = '/api/v2/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization$Response(params: DeleteOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteOrganizationResponse>> {
    return deleteOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrganization(params: DeleteOrganization$Params, context?: HttpContext): Observable<DeleteOrganizationResponse> {
    return this.deleteOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteOrganizationResponse>): DeleteOrganizationResponse => r.body)
    );
  }

  /** Path part for operation `updateOrganization()` */
  static readonly UpdateOrganizationPath = '/api/v2/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganization$Response(params: UpdateOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateOrganizationResponse>> {
    return updateOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganization(params: UpdateOrganization$Params, context?: HttpContext): Observable<UpdateOrganizationResponse> {
    return this.updateOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateOrganizationResponse>): UpdateOrganizationResponse => r.body)
    );
  }

  /** Path part for operation `getOrganizationsByUserId()` */
  static readonly GetOrganizationsByUserIdPath = '/api/v2/getOrganizationsByUserId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationsByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByUserId$Response(params: GetOrganizationsByUserId$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getOrganizationsByUserId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrganizationsByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsByUserId(params: GetOrganizationsByUserId$Params, context?: HttpContext): Observable<any> {
    return this.getOrganizationsByUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `onBoardOrganizationAndUser()` */
  static readonly OnBoardOrganizationAndUserPath = '/api/v2/onboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `onBoardOrganizationAndUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  onBoardOrganizationAndUser$Response(params: OnBoardOrganizationAndUser$Params, context?: HttpContext): Observable<StrictHttpResponse<OnBoardOrgUserResponse>> {
    return onBoardOrganizationAndUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `onBoardOrganizationAndUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  onBoardOrganizationAndUser(params: OnBoardOrganizationAndUser$Params, context?: HttpContext): Observable<OnBoardOrgUserResponse> {
    return this.onBoardOrganizationAndUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<OnBoardOrgUserResponse>): OnBoardOrgUserResponse => r.body)
    );
  }

  /** Path part for operation `getVersion()` */
  static readonly GetVersionPath = '/api/v2/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion$Response(params: GetVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersion(params: GetVersion$Params, context?: HttpContext): Observable<any> {
    return this.getVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `softDeleteOrganization()` */
  static readonly SoftDeleteOrganizationPath = '/api/v2/softDelete/{orgId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDeleteOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softDeleteOrganization$Response(params: SoftDeleteOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return softDeleteOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softDeleteOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softDeleteOrganization(params: SoftDeleteOrganization$Params, context?: HttpContext): Observable<void> {
    return this.softDeleteOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDomains()` */
  static readonly GetDomainsPath = '/api/v2/{orgId}/domains';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDomains$Response(params: GetDomains$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return getDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDomains(params: GetDomains$Params, context?: HttpContext): Observable<void> {
    return this.getDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `validateUserInfo()` */
  static readonly ValidateUserInfoPath = '/api/v1/validate-user-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateUserInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUserInfo$Response(params: ValidateUserInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateUserInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateUserInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUserInfo(params: ValidateUserInfo$Params, context?: HttpContext): Observable<void> {
    return this.validateUserInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
