import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PermissionsActionAddClassDirective } from './directives/permissions-action-addclass.directive';
import { PermissionsActionDisableDirective } from './directives/permissions-action-disable.directive';
import { PermissionsActionReadOnlyDirective } from './directives/permissions-action-readonly.directive';
import { PermissionsActionRemoveDirective } from './directives/permissions-action-remove.directive';
import { PermissionsDirective } from './directives/permissions.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [
		PermissionsDirective,
		PermissionsActionRemoveDirective,
		PermissionsActionDisableDirective,
		PermissionsActionReadOnlyDirective,
		PermissionsActionAddClassDirective
	],
	exports: [
		PermissionsDirective,
		PermissionsActionRemoveDirective,
		PermissionsActionDisableDirective,
		PermissionsActionReadOnlyDirective,
		PermissionsActionAddClassDirective
	]
})
export class PermissionsModule {}
