import { Directive, forwardRef, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PermissionsActionDirective } from './permissions-action';

@Directive({
	selector: '[shPermissionsDisable]',
	providers: [
		{
			provide: PermissionsActionDirective,
			useExisting: forwardRef(() => PermissionsActionDisableDirective)
		}
	]
})
export class PermissionsActionDisableDirective extends PermissionsActionDirective implements OnInit, OnDestroy {
	private _override$ = new BehaviorSubject(false);
	private _hasAccess$ = new BehaviorSubject(false);
	private destroy$ = new Subject<void>();

	constructor(private element: ElementRef) {
		super();
	}

	public ngOnInit(): void {
		combineLatest([this._hasAccess$, this._override$])
			.pipe(
				map(([hasAccess, overridePermission]) => overridePermission || !hasAccess),
				takeUntil(this.destroy$)
			)
			.subscribe((shouldDisable) => {
				if (shouldDisable) {
					this.element.nativeElement.setAttribute('disabled', 'true');
				} else {
					this.element.nativeElement.removeAttribute('disabled');
				}
			});
	}

	public execute(hasAccess: boolean): void {
		this._hasAccess$.next(hasAccess);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
