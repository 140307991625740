import { Directive, AfterViewInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, distinctUntilChanged, tap } from 'rxjs/operators';

import { PermissionsService } from '../services/permissions.service';

import { PermissionsActionDirective } from './permissions-action';

@Directive({
	selector: '[shPermissions]'
})
export class PermissionsDirective implements AfterViewInit, OnDestroy {
	@Input('shPermissions')
	public set permissionKey(permissionKey: string) {
		this._hasAccess.next(this.permissionService.testPermissions(permissionKey));
	}
	private destroy$ = new Subject<void>();
	private _hasAccess = new BehaviorSubject(false);

	public element!: ElementRef;
	public markForRemove = false;

	constructor(
		private permissionService: PermissionsService,
		private contentChild: PermissionsActionDirective
	) {}

	public ngAfterViewInit(): void {
		this._hasAccess
			.pipe(
				distinctUntilChanged(),
				tap((hasAccess) => {
					if (this.contentChild) {
						this.contentChild.execute(hasAccess);
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
